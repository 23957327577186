'use strict';

module.exports = {
    init: function () {
        var checkTimer = setInterval(function () {
            var $badge = $('.grecaptcha-badge');
            if ($badge.length > 0) {
                $badge.appendTo('#recaptcha_container');
                clearInterval(checkTimer);
            }
        }, 50);
    }
};
