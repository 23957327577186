'use strict';

const objectFitImages = require('object-fit-images');

$(() => {
    require('./cart/abandonedCartMessaging').init();
    require('./exchangeForm/exchangeForm').init();
    require('./login/passwordReset').init();
    require('./search/suggestion').init();
    require('./thirdParty/recaptcha').init();

    objectFitImages();
});

require('./components/spinner');
require('./util/jquery');
