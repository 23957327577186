'use strict';

const AbandonedCartMessaging = {};

/**
 * Retrieves abandoned cart messaging setting
 * @returns {boolean} Idicates whether abandoned cart messaging should be enabled
 */
function abandonedCartMessagingEnabled() {
    const miniCartQuantity = $('.minicart .cart-count-indicator.minicart-quantity');
    return miniCartQuantity && miniCartQuantity.data('abandonedCartMessagingEnabled');
}

/**
 * This will store the initial title for future use
 */
function storeInitialTitle() {
    sessionStorage.setItem('pageTitle', $(document).attr('title'));
}

/**
 * Handles the event when the user leaves the tab
 */
function addTabBlurListener() {
    $(window).on('blur', function () {
        if (!abandonedCartMessagingEnabled()) {
            return;
        }

        const miniCartQuantity = $('.minicart .cart-count-indicator.minicart-quantity');
        const hasCartItems = miniCartQuantity && +miniCartQuantity.data('item-count') > 0;
        const abandonedCartMessage = miniCartQuantity.data('abandonedCartMessage');

        if (hasCartItems && abandonedCartMessage) {
            $(document).attr('title', abandonedCartMessage);
        }
    });
}

/**
 * Handles the event when the user enters the tab again
 */
function addTabFocusListener() {
    $(window).on('focus', function () {
        if (!abandonedCartMessagingEnabled()) {
            return;
        }

        const pageTitle = sessionStorage.getItem('pageTitle');

        if (pageTitle) {
            $(document).attr('title', pageTitle);
        }
    });
}

/**
 * Listens for any abandoned cart message changes
 */
function addUpdateListener() {
    $('.minicart').on('abandonedcartmessage:update', function (event, abandonedCartMessage) {
        if (abandonedCartMessage) {
            $('.minicart .minicart-quantity').data('abandonedCartMessage', abandonedCartMessage);
        }
    });
}

AbandonedCartMessaging.init = function () {
    storeInitialTitle();
    addTabBlurListener();
    addTabFocusListener();
    addUpdateListener();
};

module.exports = AbandonedCartMessaging;
