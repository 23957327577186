'use strict';

const Suggestion = {};

Suggestion.init = () => {
    $(document).on('click', '.suggestions-close', function (e) {
        e.preventDefault();

        $('.search-field').val('');
        $('.suggestions-wrapper').empty();
        $('body').removeClass('fixed');
    });

    $(document).on('keyup focus', '.search-field[name=q]', function () {
        $('.search-field').val($(this).val());
    });

    var $mobileCheckout = $('.mobile-checkout-fixed');

    $('header').on('focus', '.search-field', function () {
        $mobileCheckout.addClass('d-none');
    });

    $('header').on('focusout', '.search-field', function () {
        $mobileCheckout.removeClass('d-none');
    });
};

module.exports = Suggestion;
