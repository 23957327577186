'use strict';

const Recaptcha = require('../util/recaptcha');
const Dialog = require('../util/dialog');
const ConfirmationPopUp = require('../util/confirmationPopUp');
const formValidation = require('../components/formValidation');
const clientSideValidation = require('../components/clientSideValidation');
const processInclude = require('base/util');

const ExchangeForm = {};

ExchangeForm.init = () => {
    if (!$('.js-exchange-form').length) return;

    ExchangeForm.onAddProductFields();
    ExchangeForm.onRemoveProductFields();
    ExchangeForm.onSubmit();
};

ExchangeForm.onSubmitSuccess = ($form, data) => {
    $form.spinner().stop();
    if (data.error) {
        ConfirmationPopUp.show(data.errorConfirmationPopup, ConfirmationPopUp.TYPE_ERROR);
        return;
    }
    if (!data.success) {
        formValidation($form, data);
        return;
    }
    ConfirmationPopUp.show(data.successConfirmationPopup, ConfirmationPopUp.TYPE_SUCCESS);
    const $emailInput = $form.find('.js-exchange-email');
    const email = $emailInput.val();
    $form.find('input:not([name="csrf_token"]), textarea').val('');
    $emailInput.val(email);
};

ExchangeForm.onSubmitError = ($form) => {
    $form.spinner().stop();
    const confirmationPopUpObject = {
        title: $form.data('error-title'),
        message: $form.data('error-message'),
        confirmButtonText: $form.data('error-button')
    };
    ConfirmationPopUp.show(confirmationPopUpObject, ConfirmationPopUp.TYPE_ERROR, true);
};

ExchangeForm.submitForm = ($form, token) => {
    const url = $form.prop('action');
    $.ajax({
        type: 'POST',
        url: url,
        data: $form.serialize() + '&g-recaptcha-response=' + token,
        success: (data) => {
            ExchangeForm.onSubmitSuccess($form, data);
        },
        error: () => {
            ExchangeForm.onSubmitError($form);
        }
    });
};

ExchangeForm.onSubmit = () => {
    $('body').on('submit', '.js-exchange-form', (event) => {
        event.preventDefault();
        const $form = $(event.currentTarget);
        $form.spinner().start();
        Recaptcha.generateToken('exchange_submit').then(token => {
            ExchangeForm.submitForm($form, token);
        }).catch(function () {
            $.spinner().stop();

            const errMsg = $('.error-messaging').data('uncaught-error-msg');
            const errTitle = $('.error-messaging').data('uncaught-error-title');
            Dialog.show(Dialog.TYPE_ERROR, errTitle, errMsg, {
                accept: {
                    text: 'OK'
                }
            });
        });
    });
};

ExchangeForm.replaceLabelForProp = ($label, count) => {
    $label.prop('for', $label.prop('for').replace('1', count));
};

ExchangeForm.replaceInputId = ($input, count) => {
    $input.prop('id', $input.prop('id').replace('1', count));
};

ExchangeForm.replaceName = ($input, count) => {
    $input.prop('name', `${$input.prop('name')}_${count}`);
};

ExchangeForm.replaceFields = ($input, $label, count) => {
    ExchangeForm.replaceLabelForProp($label, count);
    ExchangeForm.replaceInputId($input, count);
    ExchangeForm.replaceName($input, count);
};

ExchangeForm.addProductFields = () => {
    const $fieldSet = $('.js-exchange-form-product-fieldset');
    const $clonedFieldSet = $fieldSet.first().clone();
    const count = $fieldSet.length + 1;
    const $legend = $clonedFieldSet.find('legend');
    const $productLabel = $clonedFieldSet.find('label[for="exchange-productid-1"]');
    const $productInput = $clonedFieldSet.find('#exchange-productid-1');
    const $returnSizeLabel = $clonedFieldSet.find('label[for="exchange-returnsize-1"]');
    const $returnSizeInput = $clonedFieldSet.find('input#exchange-returnsize-1');
    const $receiveSizeLabel = $clonedFieldSet.find('label[for="exchange-receivesize-1"]');
    const $receiveSizeInput = $clonedFieldSet.find('input#exchange-receivesize-1');
    const $allInputs = $clonedFieldSet.find('input');
    clientSideValidation.functions.clearForm($clonedFieldSet);
    $allInputs.val('');
    $legend.text(`${$legend.html()} ${count}`);
    ExchangeForm.replaceFields($productInput, $productLabel, count);
    ExchangeForm.replaceFields($returnSizeInput, $returnSizeLabel, count);
    ExchangeForm.replaceFields($receiveSizeInput, $receiveSizeLabel, count);
    $fieldSet.last().after($clonedFieldSet);
    $('.js-exchange-remove-product-fields').removeClass('d-none');
    processInclude(clientSideValidation);
};

ExchangeForm.removeProductFields = () => {
    $('.js-exchange-form-product-fieldset').last().remove();
    if ($('.js-exchange-form-product-fieldset').length < 2) {
        $('.js-exchange-remove-product-fields').addClass('d-none');
    }
};

ExchangeForm.onAddProductFields = () => {
    $('.js-exchange-add-product-fields').click(() => {
        ExchangeForm.addProductFields();
    });
};

ExchangeForm.onRemoveProductFields = () => {
    $('.js-exchange-remove-product-fields').click(() => {
        ExchangeForm.removeProductFields();
    });
};

module.exports = ExchangeForm;
