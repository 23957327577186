'use strict';

var spinnerTimeouts = {};
var spinnerID = 0;
var fullscreenOpen = false;

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 * @param {boolean} addDelay - add delay for the spinner to show. Used for fast ajax calls to reduce screen flickering
 */
function addSpinner($target, addDelay) {
    var newID = spinnerID++;
    $target.data('spinner-id', newID);

    spinnerTimeouts[newID] = {
        result: false,
        timeout: setTimeout(function (currentSpinnerID) {
            var currentTimeout = spinnerTimeouts[currentSpinnerID];
            var $veil = $('<div class="veil"><div class="veil__underlay"></div></div>');
            $veil.append('<div class="spinner"><div class="spinner__dots"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>');

            if ($target.get(0).tagName === 'IMG') {
                $target.after($veil);
                $veil.css({ width: $target.width(), height: $target.height() });
                if ($target.parent().css('position') === 'static') {
                    $target.parent().css('position', 'relative');
                }
            } else {
                $target.append($veil);

                if ($target.css('position') === 'static') {
                    $target.parent().css('position', 'relative');
                    $target.parent().addClass('veiled');
                }
                if ($target.get(0).tagName === 'BODY') {
                    $veil.find('.spinner').css('position', 'fixed');
                }
            }
            $veil.click(function (e) {
                e.stopPropagation();
            });

            currentTimeout.result = true;
        }, addDelay ? 200 : 0, newID)
    };
}

/**
 * Remove existing spinner
 * @param {jQuery} $element - The element the spinner is on
 */
function removeSpinner($element) {
    var currentSpinnerID = $element.data('spinner-id');
    if (currentSpinnerID !== null
            && spinnerTimeouts[currentSpinnerID]
            && spinnerTimeouts[currentSpinnerID].timeout
            && !spinnerTimeouts[currentSpinnerID].result) {
        clearTimeout(spinnerTimeouts[currentSpinnerID].timeout);
        spinnerTimeouts[currentSpinnerID].timeout = null;
        spinnerTimeouts[currentSpinnerID].result = true;
    } else {
        Object.keys(spinnerTimeouts).forEach(function (key) {
            if (!spinnerTimeouts[key].result) {
                clearTimeout(spinnerTimeouts[key].timeout);
                spinnerTimeouts[key].timeout = null;
                spinnerTimeouts[key].result = true;
            }
        });

        var $veil = $('.veil');
        if ($veil.parent().hasClass('veiled')) {
            $veil.parent().css('position', '');
            $veil.parent().removeClass('veiled');
        }

        $veil.off('click');
        $veil.remove();
    }
}

// element level spinner:
$.fn.spinner = function () {
    var $element = $(this);
    var Fn = function () {
        this.start = function (addDelay) {
            if ($element.length) {
                addSpinner($element, addDelay);
            }
        };
        this.stop = function () {
            if ($element.length) {
                removeSpinner($element);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    var Fn = function () {
        this.start = function (addDelay) {
            if (!fullscreenOpen) {
                fullscreenOpen = true;
                addSpinner($('body'), addDelay);
            }
        };
        this.stop = function () {
            removeSpinner($('body'));
            fullscreenOpen = false;
        };
    };
    return new Fn();
};
